import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import rehypeReact from "rehype-react";
import Button from "../components/Button";
import ReactLightboxV2 from "../components/ReactLightboxV2";

import {
  burnhamJuniorFeaturedContent,
  burnhamJuniorGalleryHeader,
  sloughInBloomFeaturedContent,
  sloughInBloomGalleryHeader,
  eicrLandlordsFeaturedContent,  
  hsPeppersFeaturedContent,
  hsPepperGalleryHeader,
  footballCoachFeaturedContent,
  footballCoachGalleryHeader,
  motorcycleRacerFeaturedContent,
  motorcycleRacerGalleryHeader,
  burnhamFcFeaturedContent,
  burnhamFcGalleryHeader,
  scrRacingFeaturedContent,
  scrRacingGalleryHeader,
} from "../constants";

import styles from "../styles/style";

const Article = ({ data }) => {
  //console.log("data: ", data);

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { button: Button },
  }).Compiler;

  const { htmlAst } = data.markdownRemark;

  const {
    title,
    //tag,
    //author,
    heroImage,
    heroTitleTop,
    heroTitleBottom,
    excerpt,
    btnLeft,
    btnLeftLink,
    btnRight,
    btnRightLink,
  } = data.markdownRemark.frontmatter;

  const burnhamJuniorfeaturedImages = data.burnhamJuniorfeaturedImages.nodes;
  const sloughInBloomfeaturedImages = data.sloughInBloomfeaturedImages.nodes;
  const hsPeppersfeaturedImages = data.hsPeppersfeaturedImages.nodes;
  const eicrLandlordfeaturedImages = data.eicrLandlordfeaturedImages.nodes;
  const footballCoachfeaturedImages = data.footballCoachfeaturedImages.nodes;
  const motorcycleRacerfeaturedImages = data.motorcycleRacerfeaturedImages.nodes;
  const burnhamFcfeaturedImages = data.burnhamFcfeaturedImages.nodes;
  const srcRacingfeaturedImages = data.srcRacingfeaturedImages.nodes;

  let backgroundImg = getImage(heroImage?.childImageSharp?.gatsbyImageData);

  return (
    <>
      <Seo 
        title={title}
        description={excerpt}
        post={true}
        metaRobots="nofollow, index"
      />
      <HeroV8
        heroImage={backgroundImg}
        heroTitleTop={heroTitleTop}
        heroTitleBottom={heroTitleBottom}
        excerpt={excerpt}
        btnLeft={btnLeft}
        btnLeftLink={btnLeftLink}
        btnRight={btnRight}
        btnRightLink={btnRightLink}
      />

      {title && title === "Burnham Junior under 15's" ? (
        <FeaturedContent
          featuredContent={burnhamJuniorFeaturedContent}
          featuredContentImages={burnhamJuniorfeaturedImages}
        />
      ) : title && title === "Slough In Bloom" ? (
        <FeaturedContent
          featuredContent={sloughInBloomFeaturedContent}
          featuredContentImages={sloughInBloomfeaturedImages}
        />
      ) : title && title === "H&S Pepper Co" ? (
        <FeaturedContent
          featuredContent={hsPeppersFeaturedContent}
          featuredContentImages={hsPeppersfeaturedImages}
        />
      ) : title && title === "EICR for landlords" ? (
        <FeaturedContent
          featuredContent={eicrLandlordsFeaturedContent}
          featuredContentImages={eicrLandlordfeaturedImages}
        />
      ) : title && title === "Football coach" ? (
        <FeaturedContent
          featuredContent={footballCoachFeaturedContent}
          featuredContentImages={footballCoachfeaturedImages}
        />
      ) : title && title === "Racer at heart" ? (
        <FeaturedContent
          featuredContent={motorcycleRacerFeaturedContent}
          featuredContentImages={motorcycleRacerfeaturedImages}
        />
      ) : title && title === "Burnham FC" ? (
        <FeaturedContent
          featuredContent={burnhamFcFeaturedContent}
          featuredContentImages={burnhamFcfeaturedImages}
        />
      ) : title && title === "Sam Cox Racing" ? (
        <FeaturedContent
          featuredContent={scrRacingFeaturedContent}
          featuredContentImages={srcRacingfeaturedImages}
        />
      ) : null}

      

      <div className="w-full mx-auto  article mb-8">
        <div className={`bg-[#FFFFFF] ${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <section className="">
              <div>{renderAst(htmlAst)}</div>
            </section>
          </div>
        </div>
      </div>

      {title && title === "Burnham Junior under 15's" ? (
        <ReactLightboxV2
          images={data.burnhamJuniorGalleryImages.nodes}
          galleryContent={burnhamJuniorGalleryHeader}
          location="burnham-junior"
        />
      ) : title && title === "Slough In Bloom" ? (
        <ReactLightboxV2
          images={data.sloughInBloomGalleryImages.nodes}
          galleryContent={sloughInBloomGalleryHeader}
          location="slough-in-bloom"
        />
      ) : title && title === "H&S Pepper Co" ? (
        <ReactLightboxV2
          images={data.hsPepperGalleryImages.nodes}
          galleryContent={hsPepperGalleryHeader}
          location="hs-peppers"
        />
      ) : title && title === "Football coach" ? (
        <ReactLightboxV2
          images={data.footballCoachGalleryImages.nodes}
          galleryContent={footballCoachGalleryHeader}
          location="football-coach"
        />
      ) : title && title === "Racer at heart" ? (
        <ReactLightboxV2
          images={data.motorcycleRacerGalleryImages.nodes}
          galleryContent={motorcycleRacerGalleryHeader}
          location="gavin-motorcycle-racer"
        />
      ) : title && title === "Burnham FC" ? (
        <ReactLightboxV2
          images={data.burnhamFcGalleryImages.nodes}
          galleryContent={burnhamFcGalleryHeader}
          location="burnham-fc"
        />
      ) : title && title === "Sam Cox Racing" ? (
        <ReactLightboxV2
          images={data.scrRacingGalleryImages.nodes}
          galleryContent={scrRacingGalleryHeader}
          location="sam-cox-racing"
        />
      ) : null}
    </>
  );
};

export default Article;

export const query = graphql`
  query ArticleQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      htmlAst
      frontmatter {
        title
        tag
        author
        date
        title
        tag
        heroTitleTop
        heroTitleBottom
        excerpt
        btnLeft
        btnLeftLink
        btnRight
        btnRightLink
        heroImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
    }
    burnhamJuniorfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "01-intact-kit-sponsorship"
            "03-burnham-shirt-sponsorship-blue"
            "02-burnham-logo"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    sloughInBloomfeaturedImages: allFile(
      filter: {
        name: {
          in: ["slough-in-bloom-02", "slough-in-bloom-03", "slough-in-bloom-04"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    hsPeppersfeaturedImages: allFile(
      filter: {
        name: { 
          in: [            
            "08-hs-pepper-co"
            "10-hs-pepper-co"
            "12-hs-pepper-co"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    eicrLandlordfeaturedImages: allFile(
      filter: {
        name: { in: ["01-eicr-pat-services", "05-alfie-barnard", "06-eicr-pat-services"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    footballCoachfeaturedImages: allFile(
      filter: {
        name: { in: ["02-richard coaching", "01-richard coaching",  "riley"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    motorcycleRacerfeaturedImages: allFile(
      filter: {
        name: { in: ["03-gavin-little-signed-leathers", "07-black-intact-bike", "06-src-racing"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    burnhamFcfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "01-burnham-shirt-sponsorship-yellow"
            "02-burham-fc-logo"
            "03-burnham-fc-shirt-sponsorship-blue"
            
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    srcRacingfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "02-src-featured"
            "06-src-featured"
            "15-src-racing"            
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    # gallery images
    burnhamJuniorGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "02-gavin-holding-burnham-fc-shirt"
            "01-intact-kit-sponsorship"
            "03-burnham-banner-sponsorship"
            "burnham-fc-floodlight-repairs"
            "03-team-photo"
            "03-burnham-shirt-sponsorship-blue"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    sloughInBloomGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "001-slough-in-bloom-2023-overall-winner"
            "02-slough-in-bloom-gold-winners"
            "03-slough-in-bloom-gold-winners"
            "04-slough-in-bloom-gold-winners"
            "05-slough-in-bloom-gold-winners"
            "06-slough-in-bloom-gold-winners"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    hsPepperGalleryImages: allFile(
      filter: {
        name: {
          in: [            
            "06-hs-pepper-co"
            "07-hs-pepper-co"
            "11-hs-pepper-co"
            "14-hs-pepper-co"
            "15-hs-pepper-co-product"
            "16-hs-pepper-co"           
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    footballCoachGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "04-richard coaching"       
            "07-richard coaching"
            "08-richard coaching"
            "sabres-yellows-burnham-u15s-team"
            "man-of-the-match-award"
            
            "burnham-u15s-team-v4"
                
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    motorcycleRacerGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "01-gavin-little-signed-leathers"       
            "10-gavin-little-intact-electrical-signed-leathers"
            "08-gavin-little-live-racing"
            "12-gavin-little-live-racing-v3"
            "16-gavin-little-sitting-on-bike"
            "20-gavin-little-crashing-on-bike"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    burnhamFcGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "burnham-under-18s-football-club"
            "burnham-under-18s-fc-player"
            "03-burnham-banner-sponsorship"
            "burnham-fc-floodlight-repairs"
            "team-goal-celebration"
            "match-day-01"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.0
          )
        }
      }
    }
    scrRacingGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "07-src-racing"
            "09-src-racing"
            "15-src-racing"
            "18-src-racing"
            "27-src-racing"
            "30-src-racing"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.0
          )
        }
      }
    }
  }
`;
